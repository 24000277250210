import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { ReactNode } from 'react';

export interface SidebarSettingsContextValue {
    title: string | null;
    setTitle: React.Dispatch<React.SetStateAction<string | null>>;
    element: ReactNode;
    setElement: React.Dispatch<React.SetStateAction<ReactNode>>;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarSettingsContext = createContext<SidebarSettingsContextValue>({
    title: null,
    setTitle: () => { /* */ },
    element: null,
    setElement: () => { /* */ },
    open: false,
    setOpen: () => { /* */ }
});

export const SidebarSettingsProvider: FC = (props) => {
    const [title, setTitle] = useState<string | null>(null);
    const [element, setElement] = useState<ReactNode>(null);
    const [open, setOpen] = useState(false);

    const context: SidebarSettingsContextValue = useMemo(() => ({
        title,
        setTitle,
        element,
        setElement,
        open,
        setOpen
    }), [title, element, open]);

    return (
        <SidebarSettingsContext.Provider value={context} {...props} />
    );
};

export const useSidebarSettings = (title?: string, element?: ReactNode) => {
    const context = useContext<SidebarSettingsContextValue>(SidebarSettingsContext);

    if (!context) {
        throw new Error('useSidebarSettings must be used within a SidebarSettingsProvider');
    }

    useEffect(() => {
        context.setTitle(title ?? null);
        context.setElement(element);
    }, [element, title]);

    return context;
};

export const useGetSidebarSettings = () => {
    const context = useContext<SidebarSettingsContextValue>(SidebarSettingsContext);

    if (!context) {
        throw new Error('useSidebarSettings must be used within a SidebarSettingsProvider');
    }

    return context;
};
