import { lazy, Suspense } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import Loading from 'components/atoms/Loading';

// Templates
const Account = lazy(() => import('components/templates/Account'));
const Default = lazy(() => import('components/templates/Default'));
const Admin = lazy(() => import('components/templates/Admin'));

// Account pages
const ForgotPassword = lazy(() => import('components/pages/account/ForgotPassword'));
const ResetPassword = lazy(() => import('components/pages/account/ResetPassword'));
const Login = lazy(() => import('components/pages/account/Login'));
const LoginWithUserName = lazy(() => import('components/pages/account/LoginWithUserName'));
const Register = lazy(() => import('components/pages/account/Register'));

// Admin pages
const Companies = lazy(() => import('components/pages/admin/Companies'));
const Company = lazy(() => import('components/pages/admin/Companies/Company'));

// User pages
const Dashboard = lazy(() => import('components/pages/default/Dashboard'));
const StockPerArticle = lazy(() => import('components/pages/default/StockPerArticle'));
const StockPerCarrier = lazy(() => import('components/pages/default/StockPerCarrier'));
const Articles = lazy(() => import('components/pages/default/Articles'));
const Article = lazy(() => import('components/pages/default/Articles/Article'));
const Inbound = lazy(() => import('components/pages/default/Inbound'));
const InboundDetail = lazy(() => import('components/pages/default/Inbound/Detail'));
const Outbound = lazy(() => import('components/pages/default/Outbound'));
const OutboundDetail = lazy(() => import('components/pages/default/Outbound/Detail'));
const Profile = lazy(() => import('components/pages/shared/Profile'));
const Dagstand = lazy(() => import('components/pages/default/Dagstand'));
const Corrections = lazy(() => import('components/pages/default/Corrections'));

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="account" element={<Account />}>
                        <Route index element={<Login />} />
                        {
                            process.env.NODE_ENV === 'development' &&
                            <Route path="loginwithusername/:username" element={<LoginWithUserName />} />
                        }
                        <Route path="register/:code" element={<Register />} />
                        <Route path="forgot-password" element={<ForgotPassword />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                    </Route>

                    <Route path="/" element={<Default />}>
                        <Route index element={<Dashboard />} />
                        <Route path="stock-per-article" element={<StockPerArticle />} />
                        <Route path="stock-per-carrier" element={<StockPerCarrier />} />
                        <Route path="articles" element={<Articles />} />
                        <Route path="articles/:artikelNummer" element={<Article />} />
                        <Route path="inbound" element={<Inbound />} />
                        <Route path="inbound/:ontvangstNummer" element={<InboundDetail />} />
                        <Route path="outbound" element={<Outbound />} />
                        <Route path="outbound/:orderNummer" element={<OutboundDetail />} />
                        <Route path="dagstand" element={<Dagstand />} />
                        <Route path="corrections" element={<Corrections />} />
                        <Route path="profile" element={<Profile />} />
                    </Route>

                    <Route path="/admin" element={<Admin />}>
                        <Route index element={<Navigate replace to="companies" />} />
                        <Route path="companies" element={<Companies />} />
                        <Route path="companies/:id" element={<Company />} />
                        <Route path="profile" element={<Profile />} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default AppRoutes;
