import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import commonNl from './common/nl.json';
import commonEn from './common/en.json';
import loginNl from 'components/pages/account/Login/translations/nl.json';
import loginEn from 'components/pages/account/Login/translations/en.json';
import forgotPasswordNl from 'components/pages/account/ForgotPassword/translations/nl.json';
import forgotPasswordEn from 'components/pages/account/ForgotPassword/translations/en.json';
import resetPasswordNl from 'components/pages/account/ResetPassword/translations/nl.json';
import resetPasswordEn from 'components/pages/account/ResetPassword/translations/en.json';
import registerNl from 'components/pages/account/Register/translations/nl.json';
import registerEn from 'components/pages/account/Register/translations/en.json';
import accountTemplateNl from 'components/templates/Account/translations/nl.json';
import accountTemplateEn from 'components/templates/Account/translations/en.json';
import adminTemplateNl from 'components/templates/Admin/translations/nl.json';
import adminTemplateEn from 'components/templates/Admin/translations/en.json';
import defaultTemplateNl from 'components/templates/Default/translations/nl.json';
import defaultTemplateEn from 'components/templates/Default/translations/en.json';
import companyNl from 'components/pages/admin/Companies/translations/nl.json';
import companyEn from 'components/pages/admin/Companies/translations/en.json';
import dashboardNl from 'components/pages/default/Dashboard/translations/nl.json';
import dashboardEn from 'components/pages/default/Dashboard/translations/en.json';
import userNl from 'components/pages/shared/Profile/translations/nl.json';
import userEn from 'components/pages/shared/Profile/translations/en.json';
import articleNl from 'components/pages/default/Articles/translations/nl.json';
import articleEn from 'components/pages/default/Articles/translations/en.json';
import inboundNl from 'components/pages/default/Inbound/translations/nl.json';
import inboundEn from 'components/pages/default/Inbound/translations/en.json';
import outboundNl from 'components/pages/default/Outbound/translations/nl.json';
import outboundEn from 'components/pages/default/Outbound/translations/en.json';
import stockPerArticleNl from 'components/pages/default/StockPerArticle/translations/nl.json';
import stockPerArticleEn from 'components/pages/default/StockPerArticle/translations/en.json';
import stockPerCarrierNl from 'components/pages/default/StockPerCarrier/translations/nl.json';
import stockPerCarrierEn from 'components/pages/default/StockPerCarrier/translations/en.json';
import omloopsnelheidGraphNl from 'components/pages/default/Dashboard/components/Omloopsnelheid/translations/nl.json';
import omloopsnelheidGraphEn from 'components/pages/default/Dashboard/components/Omloopsnelheid/translations/en.json';
import inboundGraphNl from 'components/organisms/Graph/InboundGraph/translations/nl.json';
import inboundGraphEn from 'components/organisms/Graph/InboundGraph/translations/en.json';
import outboundGraphNl from 'components/organisms/Graph/OutboundGraph/translations/nl.json';
import outboundGraphEn from 'components/organisms/Graph/OutboundGraph/translations/en.json';
import voorraadGraphNl from 'components/pages/default/Dashboard/components/Voorraad/translations/nl.json';
import voorraadGraphEn from 'components/pages/default/Dashboard/components/Voorraad/translations/en.json';
import opslagdragerGraphNl from 'components/pages/default/Dashboard/components/Opslagdrager/translations/nl.json';
import opslagdragerGraphEn from 'components/pages/default/Dashboard/components/Opslagdrager/translations/en.json';
import inboundBarGraphNl from 'components/organisms/Graph/InboundBarGraph/translations/nl.json';
import inboundBarGraphEn from 'components/organisms/Graph/InboundBarGraph/translations/en.json';
import outboundBarGraphNl from 'components/organisms/Graph/OutboundBarGraph/translations/nl.json';
import outboundBarGraphEn from 'components/organisms/Graph/OutboundBarGraph/translations/en.json';
import headerDropdownNl from 'components/templates/Default/translations/nl.json';
import headerDropdownEn from 'components/templates/Default/translations/en.json';
import dagstandNl from 'components/pages/default/Dagstand/translations/nl.json';
import dagstandEn from 'components/pages/default/Dagstand/translations/en.json';
import mailDialogNl from 'components/organisms/MailDialog/translations/nl.json';
import mailDialogEn from 'components/organisms/MailDialog/translations/en.json';
import errorNl from 'hooks/api/error/translations/nl.json';
import errorEn from 'hooks/api/error/translations/en.json';
import searchBarNl from 'components/atoms/SearchBar/translations/nl.json';
import searchBarEn from 'components/atoms/SearchBar/translations/en.json';
import correctionsNl from 'components/pages/default/Corrections/translations/nl.json';
import correctionsEn from 'components/pages/default/Corrections/translations/en.json';

const resources = {
    nl: {
        common: commonNl,
        login: loginNl,
        forgotPassword: forgotPasswordNl,
        resetPassword: resetPasswordNl,
        register: registerNl,
        accountTemplate: accountTemplateNl,
        adminTemplate: adminTemplateNl,
        defaultTemplate: defaultTemplateNl,
        company: companyNl,
        dashboard: dashboardNl,
        user: userNl,
        article: articleNl,
        inbound: inboundNl,
        outbound: outboundNl,
        stockPerArticle: stockPerArticleNl,
        stockPerCarrier: stockPerCarrierNl,
        omloopsnelheidGraph: omloopsnelheidGraphNl,
        inboundGraph: inboundGraphNl,
        outboundGraph: outboundGraphNl,
        voorraadGraph: voorraadGraphNl,
        opslagdragerGraph: opslagdragerGraphNl,
        inboundBarGraph: inboundBarGraphNl,
        outboundBarGraph: outboundBarGraphNl,
        header: headerDropdownNl,
        dagstand: dagstandNl,
        mailDialog: mailDialogNl,
        error: errorNl,
        searchBar: searchBarNl,
        corrections: correctionsNl
    },
    en: {
        common: commonEn,
        login: loginEn,
        forgotPassword: forgotPasswordEn,
        resetPassword: resetPasswordEn,
        register: registerEn,
        accountTemplate: accountTemplateEn,
        adminTemplate: adminTemplateEn,
        defaultTemplate: defaultTemplateEn,
        company: companyEn,
        dashboard: dashboardEn,
        user: userEn,
        article: articleEn,
        inbound: inboundEn,
        outbound: outboundEn,
        stockPerArticle: stockPerArticleEn,
        stockPerCarrier: stockPerCarrierEn,
        omloopsnelheidGraph: omloopsnelheidGraphEn,
        inboundGraph: inboundGraphEn,
        outboundGraph: outboundGraphEn,
        voorraadGraph: voorraadGraphEn,
        opslagdragerGraph: opslagdragerGraphEn,
        inboundBarGraph: inboundBarGraphEn,
        outboundBarGraph: outboundBarGraphEn,
        header: headerDropdownEn,
        dagstand: dagstandEn,
        mailDialog: mailDialogEn,
        error: errorEn,
        searchBar: searchBarEn,
        corrections: correctionsEn
    }
};

export const languages = ['nl', 'en'] as const;

const ns = Object.keys(resources.nl);

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'nl',
        supportedLngs: languages,
        debug: process.env.NODE_ENV === 'development',
        ns,
        defaultNS: 'common',
        returnNull: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
