import React, { FC, useEffect } from 'react';

export interface HeaderContextValue {
    title?: string;
    setTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const HeaderContext = React.createContext<HeaderContextValue>({
    title: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTitle: () => { },
});

interface HeaderProviderProps {

}

export const HeaderProvider: FC<HeaderProviderProps> = (props) => {
    const [title, setTitle] = React.useState<string>();

    const context: HeaderContextValue = React.useMemo(() => ({
        title,
        setTitle,
    }), [title]);

    useEffect(() => {
        document.title = title != null && title !== ''
            ? 'Doeleman Logistiek - ' + title
            : 'Doeleman Logistiek';
    }, [title]);

    return (
        <HeaderContext.Provider value={context} {...props} />
    );
};

export const useHeader = (title?: string) => {
    const context = React.useContext<HeaderContextValue>(HeaderContext);

    if (!context) {
        throw new Error('useHeader must be used within a HeaderProvider');
    }

    useEffect(() => {
        context.setTitle(title);
    }, [title]);

    return context;
};
